import { Select } from 'antd'

import { useUser } from 'hooks'
import { RiceLotStatus } from 'types'
import { RICE_LOT } from 'consts'

interface Props<T> {
  onChange: (lotStatus: T) => void
  value?: T
  filterValues?: RiceLotStatus[]
}

const { Option } = Select

export const SimpleLotStatusSelect = <T extends RiceLotStatus>({
  onChange,
  value,
  filterValues,
}: Props<T>) => {
  const user = useUser()

  const organizationStatusMilestones = user?.organizationRole?.organization?.statusMilestones

  const lotStatusOptions =
    organizationStatusMilestones?.map(status => ({
      label: RICE_LOT.STATUS_NAME_MAP[status],
      value: status,
    })) ?? []

  const filteredValues = filterValues
    ? lotStatusOptions.filter(lotStatus => filterValues.includes(lotStatus.value))
    : lotStatusOptions

  return (
    <Select
      listHeight={300}
      placeholder="Sin seleccionar..."
      notFoundContent={'No hay coincidencia'}
      value={filteredValues.length ? value : undefined}
      onChange={onChange}
    >
      {filteredValues.map(lotStatus => (
        <Option key={lotStatus.value} value={lotStatus.value}>
          {lotStatus.label}
        </Option>
      ))}
    </Select>
  )
}
