import { BarChart, Bar, XAxis, CartesianGrid, LabelList, ResponsiveContainer, Cell } from 'recharts'
import { useTheme } from 'styled-components'

import { useFormatNumbersWithScale } from 'hooks'

interface Props {
  data: { firstPart: string; secondPart?: string; value: number; fortnight?: string }[]
  selectedFortnight?: string
}

export const Chart: React.FC<Props> = ({ data, selectedFortnight }) => {
  const { colors } = useTheme()
  const { formatNumberToLocale } = useFormatNumbersWithScale()

  const valueFormatter = (value: number) => {
    const formattedValue = formatNumberToLocale(value)

    return formattedValue === '0' ? undefined : formattedValue
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{
          left: 25,
          right: 25,
          top: 20,
        }}
        barSize={50}
      >
        <XAxis
          dataKey="firstPart"
          tickLine={true}
          xAxisId={0}
          dy={0}
          dx={-0}
          tick={{ fontSize: 10 }}
        />
        <XAxis
          dataKey="secondPart"
          xAxisId={1}
          dy={-15}
          tickLine={false}
          axisLine={false}
          tick={{ fontSize: 10 }}
        />

        <CartesianGrid strokeDasharray="0 0" vertical={false} />

        <Bar dataKey="value" background={{ fill: 'transparent' }} isAnimationActive={false}>
          {data.map(entry => (
            <Cell
              fill={
                selectedFortnight && selectedFortnight === entry.fortnight
                  ? colors.secondary[900]
                  : colors.milestoneUpdateType.MANUAL
              }
              key={`cell-${entry.fortnight}`}
            />
          ))}
          <LabelList
            dataKey="value"
            position="top"
            fill={colors.black}
            formatter={valueFormatter}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}
