/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useQuery } from '@apollo/client'

import {
  CropFilterContext,
  HistoricalSeasonFilterContext,
  RegionFilterContext,
  VarietyFilterContext,
} from 'contexts'
import { DashboardFiltersContext } from 'features/MainRouter/contexts'
import { MiscUtils } from 'utils'
import { LotProgressSelect, RiceLotStatus } from 'types'

import {
  ORGANIZATION_CURRENT_ANALYTICS_SUMMARY,
  OrganizationCurrentAnalyticsSummary,
  OrganizationCurrentAnalyticsSummaryVariables,
} from '../api'

const PERCENTAGE_TO_EVALUATE = 90

const isPercentageBiggerThan = (quantity: number, total: number) => {
  if (!total) return false
  return (quantity / total) * 100 > PERCENTAGE_TO_EVALUATE
}

const getDefaultLotProgress = (data: OrganizationCurrentAnalyticsSummary): LotProgressSelect => {
  const totalSizeWithPlantingDate =
    data?.organizationCurrentAnalyticsSummary.lotProgress.plantingDate.size ?? 0

  const totalSize = data?.organizationCurrentAnalyticsSummary.totals.size ?? 0

  const totalSizeWithEmergenceDate =
    data?.organizationCurrentAnalyticsSummary.lotProgress.emergenceDate.size ?? 0

  const isPlatingPercentageBiggerThan90 = isPercentageBiggerThan(
    totalSizeWithPlantingDate,
    totalSize,
  )

  if (!isPlatingPercentageBiggerThan90) return RiceLotStatus.PLANTING

  const isEmergencePercentageBiggerThan90 = isPercentageBiggerThan(
    totalSizeWithEmergenceDate,
    totalSize,
  )

  const lotProgress = isEmergencePercentageBiggerThan90
    ? RiceLotStatus.HARVEST
    : RiceLotStatus.EMERGENCE

  return lotProgress
}

export const useFetchOrganizationAnalyticsSummary = () => {
  const { region } = RegionFilterContext.useRegionFilterContext()
  const { selectedCrop } = CropFilterContext.useCropFilterContext()
  const { subregion, commercialExecutive, company, setLotProgress } =
    DashboardFiltersContext.useDashboardFiltersContext()
  const { selectedHistoricalSeason } =
    HistoricalSeasonFilterContext.useHistoricalSeasonFilterContext()
  const { selectedVarietyId } = VarietyFilterContext.useVarietyFilterContext()

  const { data, loading } = useQuery<
    OrganizationCurrentAnalyticsSummary,
    OrganizationCurrentAnalyticsSummaryVariables
  >(ORGANIZATION_CURRENT_ANALYTICS_SUMMARY, {
    variables: {
      regionId: region?.id,
      subregionId: subregion?.id,
      commercialExecutiveId: commercialExecutive?.id,
      companyId: company?.id,
      cropId: selectedCrop?.id!,
      seasonStartRange: selectedHistoricalSeason?.startRange,
      seasonEndRange: selectedHistoricalSeason?.endRange,
      varietyId: MiscUtils.formatVarietyId(selectedVarietyId),
    },
    onCompleted: newData => {
      const lotProgress = getDefaultLotProgress(newData)
      setLotProgress(lotProgress)
    },
    skip: !selectedCrop?.id,
  })

  return {
    loading,
    varieties: data?.organizationCurrentAnalyticsSummary.varieties,
    commercialExecutives: data?.organizationCurrentAnalyticsSummary.commercialExecutives,
    companies: data?.organizationCurrentAnalyticsSummary.companies,
    totals: data?.organizationCurrentAnalyticsSummary.totals,
    totalLotsWithEmergenceDate:
      data?.organizationCurrentAnalyticsSummary.lotProgress.emergenceDate.lots ?? 0,
    totalSizeWithEmergenceDate:
      data?.organizationCurrentAnalyticsSummary.lotProgress.emergenceDate.size ?? 0,
    totalLotsWithPlantingDate:
      data?.organizationCurrentAnalyticsSummary.lotProgress.plantingDate.lots ?? 0,
    totalSizeWithPlantingDate:
      data?.organizationCurrentAnalyticsSummary.lotProgress.plantingDate.size ?? 0,
    totalLotsWithHarvestDate:
      data?.organizationCurrentAnalyticsSummary.lotProgress.harvestDate.lots ?? 0,
    totalSizeWithHarvestDate:
      data?.organizationCurrentAnalyticsSummary.lotProgress.harvestDate.size ?? 0,
  }
}
