export { ReactComponent as DashboardIcon } from './navbar-dashboard.svg'
export { ReactComponent as DetailsIcon } from './navbar-details.svg'
export { ReactComponent as MapIcon } from './navbar-map.svg'
export { ReactComponent as LogoKunaIcon } from './logo-kuna.svg'
export { ReactComponent as UserIcon } from './user.svg'
export { ReactComponent as BusinessStoreIcon } from './business-store.svg'
export { ReactComponent as LotsIcon } from './lots.svg'
export { ReactComponent as HectaresIcon } from './hectares.svg'
export { ReactComponent as TonnesIcon } from './tonnes.svg'
export { ReactComponent as CalendarIcon } from './calendar.svg'
export { ReactComponent as CalendarPrevArrowIcon } from './calendar-prev-arrow.svg'
export { ReactComponent as CalendarNextArrowIcon } from './calendar-next-arrow.svg'
export { ReactComponent as PolygonIcon } from './polygon.svg'
export { ReactComponent as HiddenEyeIcon } from './hidden-eye.svg'
export { ReactComponent as TrashIcon } from './trash.svg'
export { ReactComponent as LogoDianaIcon } from './logo-diana.svg'
export { ReactComponent as EmailIcon } from './email.svg'
export { ReactComponent as PasswordIcon } from './password.svg'
export { ReactComponent as LocationIcon } from './location.svg'
export { ReactComponent as LogoKunaLoginIcon } from './logo-kuna-login.svg'
export { ReactComponent as SettingsIcon } from './navbar-settings.svg'
export { ReactComponent as ShareArrowIcon } from './share-arrow.svg'
export { ReactComponent as HarvestIcon } from './navbar-harvest.svg'
export { ReactComponent as SalesIcon } from './navbar-sales.svg'
