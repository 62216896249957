import { orderBy } from 'lodash'

import { NumberUtils } from 'utils'
import { LotProgressSelect, RiceLotStatus } from 'types'
import { DashboardFiltersContext } from 'features/MainRouter/contexts'

import { OrganizationAnalyticsSummaryVariety } from './api'
import { useFetchOrganizationAnalyticsSummary } from './hooks'
import { DataObject } from '../../types'

type LotProgressData = {
  [key in LotProgressSelect]: {
    lotsQtyData: {
      value: number
    }[]
    sizeData: {
      value: number
    }[]
    completedTitle: string
    uncompletedTitle: string
  }
}

export const useOrganizationAnalyticsSummary = () => {
  const {
    commercialExecutives,
    companies,
    varieties,
    totals,
    loading,
    totalLotsWithEmergenceDate,
    totalSizeWithEmergenceDate,
    totalLotsWithPlantingDate,
    totalSizeWithPlantingDate,
    totalLotsWithHarvestDate,
    totalSizeWithHarvestDate,
  } = useFetchOrganizationAnalyticsSummary()
  const { lotProgress } = DashboardFiltersContext.useDashboardFiltersContext()
  const lotsQty = totals?.lots ?? 0
  const haQty = totals?.size ? NumberUtils.roundedNumber(totals.size) : 0

  const handleChartData = (
    organizationAnalyticsSummaryVarieties?: OrganizationAnalyticsSummaryVariety[],
  ): DataObject[] => {
    if (!organizationAnalyticsSummaryVarieties) return []

    const varietiesList =
      varieties?.map(variety => {
        return {
          name: variety.variety?.locales.es ?? 'Sin variedad',
          lots: variety.totals.lots,
          size: NumberUtils.roundedNumber(variety.totals.size),
        }
      }) ?? []

    const orderedVarieties = orderBy(varietiesList, ['size'], ['desc'])
    return orderedVarieties
  }

  const lotProgressData: LotProgressData = {
    [RiceLotStatus.PLANTING]: {
      completedTitle: 'Sembrado',
      uncompletedTitle: 'Sin sembrar',
      lotsQtyData: [
        { value: totalLotsWithPlantingDate },
        { value: lotsQty - totalLotsWithPlantingDate },
      ],
      sizeData: [
        { value: totalSizeWithPlantingDate },
        { value: Math.round(haQty - totalSizeWithPlantingDate) },
      ],
    },
    [RiceLotStatus.EMERGENCE]: {
      completedTitle: 'Emergido',
      uncompletedTitle: 'Sin emerger',
      lotsQtyData: [
        { value: totalLotsWithEmergenceDate },
        { value: lotsQty - totalLotsWithEmergenceDate },
      ],
      sizeData: [
        { value: totalSizeWithEmergenceDate },
        { value: Math.round(haQty - totalSizeWithEmergenceDate) },
      ],
    },
    [RiceLotStatus.HARVEST]: {
      completedTitle: 'Cosechado',
      uncompletedTitle: 'Sin cosechar',
      lotsQtyData: [
        { value: totalLotsWithHarvestDate },
        { value: lotsQty - totalLotsWithHarvestDate },
      ],
      sizeData: [
        { value: totalSizeWithHarvestDate },
        { value: Math.round(haQty - totalSizeWithHarvestDate) },
      ],
    },
  }

  return {
    loading,
    varieties,
    commercialExecutives: commercialExecutives ?? [],
    companies: companies ?? [],
    lotsQty,
    haQty,
    varietiesChartData: handleChartData(varieties),
    lotsQtyData: lotProgressData[lotProgress].lotsQtyData,
    sizeData: lotProgressData[lotProgress].sizeData,
    completedTitle: lotProgressData[lotProgress].completedTitle,
    uncompletedTitle: lotProgressData[lotProgress].uncompletedTitle,
  }
}
