import { RiceLotStatus } from './graphql'

export enum Selects {
  NO_VARIETY = 'NO_VARIETY',
  ALL_VARIETIES = 'ALL_VARIETIES',
  ALL_OPTIONS = 'ALL_OPTIONS',
}

export type SelectValueType<T> = T | Selects.ALL_OPTIONS

export type LotProgressSelect =
  | RiceLotStatus.PLANTING
  | RiceLotStatus.EMERGENCE
  | RiceLotStatus.HARVEST
