import { Button, Col, Row, Space } from 'antd'
import styled, { useTheme } from 'styled-components'
import { useState } from 'react'

import { CardSection, Loader, SimpleLotStatusSelect } from 'components'
import { ItemInfo } from 'features/MainRouter/components'
import { Ball } from 'components'
import { DashboardFiltersContext } from 'features/MainRouter/contexts'
import { LotProgressSelect, RiceLotStatus } from 'types'

import { LoaderContainer as Container } from '..'
import { Chart } from './components'

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  min-height: 350px;
`

const StyledButton = styled(Button)`
  background-color: transparent !important;
  & :hover {
    background-color: transparent !important;
  }
`

const MarginCol = styled(Col)`
  margin-top: 10px;
`

type ChartDataObject = {
  value: number
}

interface Props {
  lotsQtyData: ChartDataObject[]
  sizeData: ChartDataObject[]
  isLoading: boolean
  completedTitle: string
  uncompletedTitle: string
}
type ViewActive = 'lots' | 'area'

const FILTER_VALUES: LotProgressSelect[] = [
  RiceLotStatus.PLANTING,
  RiceLotStatus.EMERGENCE,
  RiceLotStatus.HARVEST,
]

export const EmergenceDatesCard: React.FC<Props> = ({
  lotsQtyData,
  isLoading,
  sizeData,
  completedTitle,
  uncompletedTitle,
}) => {
  const [viewActive, setViewActive] = useState<ViewActive>('area')
  const isLotsViewActive = viewActive === 'lots'
  const isAreaViewActive = viewActive === 'area'
  const { colors } = useTheme()
  const { lotProgress, setLotProgress } = DashboardFiltersContext.useDashboardFiltersContext()

  const data = isLotsViewActive ? lotsQtyData : sizeData
  return (
    <CardSection title="Avances">
      {isLoading ? (
        <Container $minHeight="352px" align="middle" justify="center">
          <Loader />
        </Container>
      ) : (
        <StyledCol>
          <SimpleLotStatusSelect
            onChange={setLotProgress}
            value={lotProgress}
            filterValues={FILTER_VALUES}
          />
          <MarginCol>
            <Chart data={data} />
          </MarginCol>
          <Row justify="center">
            <MarginCol span={14}>
              <ItemInfo
                Icon={<Ball background={colors.primary[500]} />}
                title={completedTitle}
                data={data[0].value.toFixed()}
              />
            </MarginCol>
            <MarginCol span={14}>
              <ItemInfo
                Icon={<Ball background={colors.baseColor[700]} />}
                title={uncompletedTitle}
                data={data[1].value.toFixed()}
              />
            </MarginCol>
          </Row>
          <MarginCol>
            <Space size={16}>
              <StyledButton
                type={isAreaViewActive ? 'default' : 'text'}
                onClick={() => setViewActive('area')}
              >
                Hectáreas
              </StyledButton>
              <StyledButton
                type={isLotsViewActive ? 'default' : 'text'}
                onClick={() => setViewActive('lots')}
              >
                Lotes
              </StyledButton>
            </Space>
          </MarginCol>
        </StyledCol>
      )}
    </CardSection>
  )
}
