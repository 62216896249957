import { useQuery } from '@apollo/client'
import { useMemo, useState } from 'react'
import dayjs from 'dayjs'

import {
  CropFilterContext,
  HistoricalSeasonFilterContext,
  RegionFilterContext,
  VarietyFilterContext,
} from 'contexts'
import { HarvestFiltersContext } from 'features/MainRouter/contexts'
import { MiscUtils } from 'utils'
import {
  DeliveryCondition,
  GrainType,
  ProductionType,
  RiceLotStatus,
  SelectValueType,
  Selects,
} from 'types'

import {
  OrganizationStats,
  OrganizationStatsVariables,
  ORGANIZATION_STATS,
  HarvestOrganizationStats,
  HarvestOrganizationStatsVariables,
  HARVEST_ORGANIZATION_STATS,
  OrganizationStatsByStatus,
  OrganizationStatsByStatusVariables,
  ORGANIZATION_STATS_BY_STATUS,
} from './api'
import { useHarvestedCalendar } from '../useHarvestedCalendar'

export const useHarvestStats = () => {
  const { region } = RegionFilterContext.useRegionFilterContext()
  const { selectedCrop } = CropFilterContext.useCropFilterContext()
  const { selectedVarietyId, setSelectedVarietyId } = VarietyFilterContext.useVarietyFilterContext()
  const { selectedHistoricalSeason, setSelectedHistoricalSeason } =
    HistoricalSeasonFilterContext.useHistoricalSeasonFilterContext()
  const { company, subregion, setCompany, setSubregion } =
    HarvestFiltersContext.useHarvestFiltersContext()
  const [selectedStatus, setSelectedStatus] = useState(RiceLotStatus.HARVEST)
  const [selectedGrainType, setSelectedGrainType] = useState<SelectValueType<GrainType>>(
    Selects.ALL_OPTIONS,
  )
  const [selectedDeliveryCondition, setSelectedDeliveryCondition] = useState<
    SelectValueType<DeliveryCondition>
  >(Selects.ALL_OPTIONS)
  const [selectedProductionType, setSelectedProductionType] = useState<
    SelectValueType<ProductionType>
  >(Selects.ALL_OPTIONS)
  const [selectedFortnight, setSelectedFortnight] = useState<string>()

  const cropId = selectedCrop?.id

  const { data, loading } = useQuery<OrganizationStats, OrganizationStatsVariables>(
    ORGANIZATION_STATS,
    {
      variables: {
        regionId: region?.id,
        subregionId: subregion?.id,
        companyId: company?.id,
        cropId: cropId!,
        varietyId: MiscUtils.formatVarietyId(selectedVarietyId),
        seasonStartRange: selectedHistoricalSeason?.startRange,
        seasonEndRange: selectedHistoricalSeason?.endRange,
        status: RiceLotStatus.HARVEST,
      },
      skip: !cropId,
    },
  )

  const { data: harvestOrganizationStats, loading: harvestOrganizationStatsLoading } = useQuery<
    HarvestOrganizationStats,
    HarvestOrganizationStatsVariables
  >(HARVEST_ORGANIZATION_STATS, {
    variables: {
      regionId: region?.id,
      subregionId: subregion?.id,
      companyId: company?.id,
      cropId: cropId!,
      varietyId: MiscUtils.formatVarietyId(selectedVarietyId),
      seasonStartRange: selectedHistoricalSeason?.startRange,
      seasonEndRange: selectedHistoricalSeason?.endRange,
      status: RiceLotStatus.HARVEST,
      grainType: MiscUtils.formatSelectValue(selectedGrainType),
      deliveryCondition: MiscUtils.formatSelectValue(selectedDeliveryCondition),
      productionType: MiscUtils.formatSelectValue(selectedProductionType),
    },
    skip: !cropId,
    onCompleted: harvestOrganizationStatsData => {
      if (!harvestOrganizationStatsData.organizationStats.fortnights.length) return
      setSelectedFortnight(harvestOrganizationStatsData.organizationStats.fortnights[0].name)
    },
  })

  const { data: organizationStatsByStatus, loading: organizationStatsByStatusLoading } = useQuery<
    OrganizationStatsByStatus,
    OrganizationStatsByStatusVariables
  >(ORGANIZATION_STATS_BY_STATUS, {
    variables: {
      regionId: region?.id,
      subregionId: subregion?.id,
      companyId: company?.id,
      cropId: cropId!,
      varietyId: MiscUtils.formatVarietyId(selectedVarietyId),
      seasonStartRange: selectedHistoricalSeason?.startRange,
      seasonEndRange: selectedHistoricalSeason?.endRange,
      status: selectedStatus,
      grainType: MiscUtils.formatSelectValue<GrainType>(selectedGrainType),
      deliveryCondition: MiscUtils.formatSelectValue<DeliveryCondition>(selectedDeliveryCondition),
      productionType: MiscUtils.formatSelectValue<ProductionType>(selectedProductionType),
    },
    skip: !cropId,
  })

  const dateRange = useMemo(() => {
    const fortnightDateRange = harvestOrganizationStats?.organizationStats.fortnights.find(
      fortnight => fortnight.name === selectedFortnight,
    )?.dateRange

    if (!fortnightDateRange) return undefined

    return { startDate: fortnightDateRange.startDate, endDate: fortnightDateRange.endDate }
  }, [harvestOrganizationStats?.organizationStats.fortnights, selectedFortnight])

  const { data: harvestedCalendar, loading: isHarvestedCalendarLoading } = useHarvestedCalendar({
    riceLotStatus: [RiceLotStatus.HARVEST],
    dateRange,
    regionId: region?.id,
    subregionId: subregion?.id,
    companyId: company?.id,
    cropId,
    seasonStartRange: selectedHistoricalSeason?.startRange,
    seasonEndRange: selectedHistoricalSeason?.endRange,
    varietyId: MiscUtils.formatVarietyId(selectedVarietyId),
    grainType: MiscUtils.formatSelectValue<GrainType>(selectedGrainType),
    deliveryCondition: MiscUtils.formatSelectValue<DeliveryCondition>(selectedDeliveryCondition),
    productionType: MiscUtils.formatSelectValue<ProductionType>(selectedProductionType),
    skip: !selectedFortnight || !dateRange,
  })

  const parseFortnightName = (name: string) => {
    const [, month, fortnight] = name.split('_')
    const fortnightName = fortnight === 'FIRST' ? '1ra' : '2da'
    const date = dayjs().month(Number(month))
    const monthName = date.locale('es-ES').format('MMM')
    return { name: `${fortnightName} Q`, monthName }
  }

  const sortByYearMonthQuarter = (dataToOrder: { key: string; value: number }[]) => {
    return dataToOrder.sort((a, b) => {
      // Split keys into parts to compare year, month, and quarter
      const [yearA, monthA, quarterA] = a.key.split(/_|_/)
      const [yearB, monthB, quarterB] = b.key.split(/_|_/)

      if (yearA !== yearB) {
        return parseInt(yearA) - parseInt(yearB)
      }

      if (monthA !== monthB) {
        return parseInt(monthA) - parseInt(monthB)
      }

      if (quarterA !== quarterB) {
        if (quarterA === 'FIRST') return -1
        return 1
      }

      return 0
    })
  }

  const areaChartData = [
    { value: harvestOrganizationStats?.organizationStats.totals.area ?? 0, firstPart: 'Total' },
    {
      value: harvestOrganizationStats?.organizationStats.totals.plantedArea ?? 0,
      firstPart: 'Sembrado',
    },
    {
      value: harvestOrganizationStats?.organizationStats.totals.emergedArea ?? 0,
      firstPart: 'Emergido',
    },
    {
      value: harvestOrganizationStats?.organizationStats.totals.harvestedArea ?? 0,
      firstPart: 'Cosechado',
    },
  ]

  const orderedTotalsFortnightsStatsByYield = sortByYearMonthQuarter([
    ...(harvestOrganizationStats?.organizationStats.totals.fortnightsStats.byYield ?? []),
  ])

  const harvestByYield =
    orderedTotalsFortnightsStatsByYield.map(stat => {
      const { monthName, name } = parseFortnightName(stat.key)
      return {
        firstPart: name,
        secondPart: monthName,
        fortnight: stat.key,
        value: Number((stat.value / 1000).toFixed()),
      }
    }) ?? []

  const harvestChartData = [
    ...harvestByYield,
    {
      value: harvestOrganizationStats?.organizationStats.totals.notEmergedYield
        ? Number(
            (harvestOrganizationStats?.organizationStats.totals.notEmergedYield / 1000).toFixed(),
          )
        : 0,
      firstPart: 'No',
      secondPart: 'emergido',
    },
  ]

  const orderedTotalsFortnightsStatsByArea = sortByYearMonthQuarter([
    ...(organizationStatsByStatus?.organizationStats.totals.fortnightsStats.byArea ?? []),
  ])

  const harvestByStatus =
    orderedTotalsFortnightsStatsByArea.map(stat => {
      const { monthName, name } = parseFortnightName(stat.key)
      return {
        secondPart: monthName,
        firstPart: name,
        fortnight: stat.key,
        value: stat.value,
      }
    }) ?? []

  const fortnightSelectOptions =
    harvestOrganizationStats?.organizationStats.fortnights.map(fortnight => {
      const { monthName, name } = parseFortnightName(fortnight.name)
      return {
        label: `${name} ${monthName}`,
        value: fortnight.name,
      }
    }) ?? []

  return {
    setSubregion,
    setCompany,
    setSelectedHistoricalSeason,
    setSelectedVarietyId,
    company,
    selectedHistoricalSeason,
    region,
    subregion,
    selectedVarietyId,
    cropId: selectedCrop?.id,
    harvestStats: data?.organizationStats,
    loading,
    harvestChartData,
    areaChartData,
    harvestByStatus,
    selectedStatus,
    setSelectedStatus,
    harvestOrganizationStatsLoading,
    organizationStatsByStatusLoading,
    selectedGrainType,
    selectedDeliveryCondition,
    selectedProductionType,
    setSelectedGrainType,
    setSelectedDeliveryCondition,
    setSelectedProductionType,
    fortnightSelectOptions,
    selectedFortnight,
    setSelectedFortnight,
    harvestedCalendarData: harvestedCalendar?.organizationAnalytics.lots,
    isHarvestedCalendarLoading,
  }
}
